import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const drawerWidth = 240;

export default function NavBarTabs() {
  const [value, setValue] = React.useState('0');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sectionData = ["Coming soon...", "Coming very soon...", "Seriously, soon....", "congrats! you got to the fourth iteration of....soon!"];

  const sectionTitles = ["About Me", "Resume", "Research", "community Outreach"];

  return (
    <Box sx={{ display: {xs: 'none', sm: 'block'}, width: `calc(100% - ${drawerWidth}px)`, typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            {sectionTitles.map((value, index) => (
              <Tab 
                label={value}
                key={index}
                value={index.toString()}
              />
            ))}
          </TabList>
        </Box>
        {sectionData.map((value, index) => (
          <TabPanel
            key={index}
            value={index.toString()}
          >
            {value}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}