import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Avatar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const drawerWidth = 240;

const sideBarText = ['Github', 'LinkedIn', 'Scholar', 'Research Gate', 'Research Lab', 'Instagram', 'Mail'];
const sideBarIcons = ['fa-brands fa-github', "fa-brands fa-linkedin", "fa-brands fa-google-scholar", "fa-brands fa-researchgate", "fa-solid fa-flask-vial", "fa-brands fa-instagram", "fa-regular fa-envelope"];
const sideBarLinks = [
    "https://github.com/jonperry-dev",
    "https://www.linkedin.com/in/perryjon/",
    "https://scholar.google.com/citations?user=OcyjbIkAAAAJ&hl=en",
    "https://www.researchgate.net/profile/Jonathan-Perry-7",
    "https://sites.google.com/view/amandafernandez/research/vision-ai-lab",
    "https://www.instagram.com/jonperry.dev/",
    "mailto:jon@jonperry.dev"
];

export default function SideBar() {
  return (
    <Box 
        sx={{ display: {xs: 'none', sm: 'flex'} }}
        direction="column"
        alignItems="center"
    >
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Box
            alignSelf={'center'}
            flexDirection={'column'}
            paddingBottom={'1em'}
        >
        <Avatar
            alt="Jon Perry"
            src="https://avatars.githubusercontent.com/u/125411632?v=4"
            sx={{ width: 128, height: 128}}
        />
        </Box>
        <Box flexShrink={0} paddingBottom={'2em'}>
            <Typography variant='h6'>
                Jon Perry
            </Typography>
            <Typography variant='subtitle2' sx={{fontSize: '75%'}}>
                Machine Learning Engineer
            </Typography>
            <Typography variant='subtitle2' sx={{fontSize: '75%'}}>
                Toyota Connected North America
            </Typography>
        </Box>
        <Divider />
        <Box flexShrink={0} paddingTop={'2em'}>
            <List>
            {sideBarText.map((text, index) => (
                <ListItem key={text} disablePadding>
                <ListItemButton
                target='_blank'
                    href={sideBarLinks.at(index)}
                    sx={{
                        textAlign: 'left',
                        justifyContent: 'space-evenly',
                        paddingLeft: '3em'
                    }}
                >
                    <Box
                        flexGrow={'inherit'} 
                        flexDirection='column'
                    >
                        <FontAwesomeIcon icon={sideBarIcons.at(index)} />
                    </Box>
                    <Box
                        flexDirection='column'
                        flexGrow={'inherit'}
                    >
                        <ListItemText primary={text} />
                    </Box>
                    
                </ListItemButton>
                </ListItem>
            ))}
            </List>
        </Box>
      </Drawer>
    </Box>
  );
}